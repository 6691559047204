import React, { useCallback, useEffect, useState } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

export const LiveAgent = ({ deploymentId, organizationId, buttonId, endpoint, scriptSrc }) => {
  const [isLoading, setIsLoading] = useState(true);

  const onlineId = `liveagent_button_online_${buttonId}`;
  const offlineId = `liveagent_button_offline_${buttonId}`;

  const initializeAgent = useCallback(() => {
    liveagent.init(endpoint, deploymentId, organizationId);

    if (!window._laq) {
      window._laq = [];
    }

    window._laq.push(() => {
      liveagent.showWhenOffline(buttonId, document.getElementById(offlineId));
      liveagent.showWhenOnline(buttonId, document.getElementById(onlineId));
    });

    setIsLoading(false);
  }, [buttonId, deploymentId, endpoint, offlineId, onlineId, organizationId]);

  const initializeChat = () => {
    liveagent.startChat(buttonId);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptSrc;
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      initializeAgent();
    });
  }, [initializeAgent, scriptSrc]);

  return (
    <div className={cn('live-agent-container', { 'is-hidden': isLoading })}>
      <button style={{ display: 'none' }} id={onlineId} onClick={initializeChat}>
        Have a question? Chat with us.
      </button>
      <span style={{ display: 'none' }} id={offlineId} />
    </div>
  );
};

LiveAgent.propTypes = {
  deploymentId: string,
  organizationId: string,
  buttonId: string,
  endpoint: string,
  scriptSrc: string,
};
